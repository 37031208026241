<template>
  <div id="todo-container">
    <h2><i class="fad fa-fw fa-list"></i> To Do</h2>

    <ComingSoonBanner></ComingSoonBanner>

    <!--<div class="row mt-4">
      <div v-for="todo in todos" class="col-sm-6 mb-4" :key="todo.id">
        <div class="card h-100">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h3 class="card-title">{{ todo.name }}</h3>
              </div>
              <div class="col-auto text-right">
                <i @click="todo.done = !todo.done" class="fad fa-2x mr-1" :class="todo.done ? 'fa-check-square' : 'fa-square'"></i>
              </div>
            </div>
            <p>{{ todo.description }}</p>

            <h4>Attached Items</h4>
            <div v-for="attachment in Object.keys(todo.attached)" :key="attachment">
              <h5>{{ attachment }}</h5>
              <ul>
                <li v-for="attachedItem in todo.attached[attachment]" :key="attachedItem">{{ attachedItem }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
// @ is an alias to /src

import ComingSoonBanner from "@/components/ComingSoonBanner";
export default {
  name: 'Todos',
  components: {
    ComingSoonBanner
  },
  data() {
    return {
      todos: [
        {
          id: 0,
          name: "Build Attachment Structure",
          createdAt: +Date.now(),
          creatorId: 1,
          attached: {
            tools: [ "Notes", "Roadmaps", "Todos" ],
            journeys: [ "Main" ],
            personas: [ "Peter" ],
            values: ["Comprehensive Overview" ],
            kpis: [ "Sale" ],
          },
          deadline: (+Date.now() + 4915127679),
          description: "Desired Behaviour: I can connect every item from the Toolbox to this todo and therefore easily generate metadata for filtering and additional information.",
          done: false,
        },
        {
          id: 1,
          name: "Build MVP Database",
          createdAt: +Date.now(),
          creatorId: 1,
          attached: {
            tools: [ "Traffic Channels", "Link Tree", "Keyword Planner", "Content Library", "Competition Benchmark", "Customer Journey Maps", "Site Audits", "Findings Pool", "Personas", "Value Propositions", "Style Guide", "KPI Tool", "Best Practices", "Idea Backlog", "Experiments", "Personalisation", "Success Dashboard" ],
          },
          deadline: (+Date.now() + 4915127679),
          description: "Desired Behaviour: Be able to persistently save items, make changes and delete them.",
          done: false,
        },
        {
          id: 2,
          name: "Build Scraper",
          createdAt: +Date.now(),
          creatorId: 1,
          attached: {
            tools: [ "Link Tree", "Customer Journey Maps", "Site Audits", "Style Guide" ],
          },
          deadline: (+Date.now() + 4915127679),
          description: "Desired Behaviour: I can automatically scrape my site and output Link Tree Maps and visual copies of pages.",
          done: false,
        },
        {
          id: 3,
          name: "Google Analytics / Adobe Analytics / Google Ads / Search Console Integrations",
          createdAt: +Date.now(),
          creatorId: 1,
          attached: {
            tools: [ "Traffic Channels", "Keyword Planner", "Link Tree", "KPI Tool", "Success Dashboard" ],
          },
          deadline: (+Date.now() + 4915127679),
          description: "Desired Behaviour: I can collect metrics from Analytics Tools and map them to KPIs. Also I can measure Traffic Channels, Link Tree Pageviews and Keyword Performance.",
          done: false,
        },
        {
          id: 4,
          name: "CSV Upload and Configuration",
          createdAt: +Date.now(),
          creatorId: 1,
          attached: {
            tools: [ "Traffic Channels", "Keyword Planner", "Link Tree", "Roadmaps", "To Do" ],
          },
          deadline: (+Date.now() + 4915127679),
          description: "Desired Behaviour: I can upload custom reports, if I don't have integrations activated and therefore am able to customize how my file is read, to match the internal structures.",
          done: false,
        },
      ]
    }
  }
}
</script>
